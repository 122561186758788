li {
    list-style-type: none;
  }
  
.page-link{
    font-size: 14px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #ccc; 
    margin: 0 5px;
    
}
.table tbody tr.selected {
    background-color: #e6e4e4;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
}

.pagination .page-num {
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 200;
    
  }
  

.pagination .page-num:hover{
    background-color: #5ab188;
    color: #fff;
}
.pagination .active{
    background-color: #5ab188;
}