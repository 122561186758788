body {
  margin: 0;
  font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
     "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}

a {
  color: rgb(2, 2, 2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.colCriticidade{
  padding-bottom: 0px;
}
.tbCrit{
  margin-top: 6px;
}
.lnSepareteTable{
  border-left: 2px solid #ececec;
  margin-top: 20px;
  height: 315px;
  float: left;
  padding-left: 25px;
}
.tbPerformace{float: right;}
/* @import "~react-vis/dist/style"; */
/*
.modal-content {
    margin: 90px -100px 30px 0px;
} */
.pagination a{
  border: 0px;
}
.pagination-list{
  margin-left: -5em;
}
.my-message {
  position: absolute;
  right: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.my-modal {
  /* position: absolute; */
}

.saved {
  background-color: rgb(164, 240, 164);
  color: rgb(22, 75, 22);
}

.badge {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  background: rgb(238, 36, 10);
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -15px;
  transition: all 0.3s;
}

progress.show-value {
  position: relative;
}

progress.show-value:after {
  content: attr(value) "%";
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: calc(1rem / 1.5);
  line-height: 1rem;
  color: rgb(2, 2, 2);
}

progress.show-value.is-small:after {
  font-size: calc(0.75rem / 1.5);
  line-height: 0.75rem;
}

progress.show-value.is-medium:after {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
}

progress.show-value.is-large:after {
  font-size: calc(1.5rem / 1.5);
  line-height: 1.5rem;
}

.footer-fixed {
  position: fixed;
  background-color: white;
  top: 80%;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;600;700&display=swap");

:root {
  --header-height: 0;
  --nav-width: 88px;
  --first-color: #131b5e;
  --first-color-light: #fcfcfc;
  --white-color: #fdfdfd;
  --select-color: #c6324b;
  --body-font: "Ubuntu", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 1;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);

  z-index: var(--z-fixed);
  transition: 0.5s;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.header_toggle {
  color: var(--first-color);
  font-size: 2.5rem;
  cursor: pointer;
}

.header_img {
  width: 55px;
  height: 35px;
  display: flex;
  justify-content: center;

  overflow: hidden;
}

.header_img img {
  width: 55px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  /*background: linear-gradient(0deg, rgba(42,41,91,1) 40%, rgba(231,52,87,1) 60%);*/
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 1rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav_logo-name-primary {
  color: var(--white-color);
  font-weight: 700;
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700;
  padding-left: 1rem;
}

.nav_name {
  color: var(--white-color);
  font-weight: 700;
  padding-left: 1rem;
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: 0.3s;
  width: 215px;
  padding-top: 0.5rem;
  border-radius: 0.5rem;
}

.nav_link.small {
  width: 68px;
}

.nav_link.selected {
  background-color: red;
  /*color:#222f57;*/
}
/*.nav_link.selected>.nav_logo-name {
  color:#222f57;
}*/
/*.nav_link:hover {
  color:#222f57;
   font-weight: 0; 
}*/
/*
.nav_link.selected:hover {
  background-color: red;
}*/

.nav_icon {
  font-size: 1.25rem;
}

.shows {
  left: 0;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active1 {
  color: var(--white-color);
}

.active1::before {
  content: "";
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100vh;
}

@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 2rem) 1rem 0 0;
    padding-left: calc(var(--nav-width) - 4.8rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
  }

  .header_img {
    width: 55px;
    height: 40px;
  }

  .header_img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 0 1rem 0 0;
  }

  .shows {
    width: calc(var(--nav-width) + 150px);
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 170px);
    animation-duration: 2s;
    animation-delay: 2s;
  }

  .body-small {
    padding-left: calc(var(--nav-width) + 20px);
    animation-duration: 2s;
    animation-delay: 2s;
  }
}

/* BASIC */

/* html {
    background-color: #ffffff;
  }

  body {
    font-family: "Poppins", sans-serif;
    height: 82.0vh;
  } */

/* a {
    color: #92badd;
    display:inline-block;
    text-decoration: none;
    font-weight: 400;
  } */

/* h2 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display:inline-block;
    margin: 40px 8px 10px 8px;
    color: #cccccc;
  }
   */

/* STRUCTURE */

/* .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
  } */

/* #formContent {
    -webkit-border-radius: 10px 10px 10px 10px;
    border-radius: 10px 10px 10px 10px;
    background: #fff;
    padding: 30px;
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0px;
    -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
    text-align: center;
  } */

/* #formFooter {
    background-color: #f6f6f6;
    border-top: 1px solid #dce8f1;
    padding: 25px;
    text-align: center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
  }
   */

/* TABS */

/* h2.inactive {
    color: #cccccc;
  }

  h2.active1 {
    color: #0d0d0d;
    border-bottom: 2px solid #5fbae9;
  }
   */

/* FORM TYPOGRAPHY*/

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.insert {
  background-color: var(--first-color);
  color: white;
}

.insert:hover {
  background-color: rgb(56, 36, 165);
  color: white;
}

.fixedbutton {
  z-index: 1;
  position: fixed;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  height: 95px;

  width: 95px;
  text-align: center;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fixedbutton:hover {
  height: 95px;

  width: 95px;
  /* background-color: red; */
  box-shadow: 8px 16px 16px 8px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Style the tab */
.tab {
  overflow: hidden;
  /* border: 1px solid #ccc; */
  /* background-color: #f1f1f1; */
}

/* Style the buttons that are used to open the tab content */
.tab button {
  /* background-color: inherit; */
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 6px;
  transition: 0.3s;
  /* border-radius: 90%; */
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active1/current tablink class */
.tab button.active1 {
  background-color: var(--select-color);
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-top: none;
}

.tabcontent {
  animation: fadeEffect 1s; /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

li {
  font-size: 18px;
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 1px;
  list-style-type: circle;
}

.dropdown-container {
  padding-left: 45px;
  font-size: 13px;
}
