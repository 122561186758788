.logged-container {
  margin-top: 2.5rem;
}

.has-text-centered {
  left: 35% !important;
}

@media screen and (maz-width: 768px) {
  .has-text-centered {
    left: 30% !important;
  }
}
