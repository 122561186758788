.top-border {
    border-top: 1px solid #ccc;
}

.right-border {
    border-right: 1px solid #ccc;
}

label {
    margin-bottom: 0;
}

.label:not(:last-child) {
    margin-bottom: 0;
}

.textarea.is-static {
    resize: none;
}

.text-darkblue {
    color: #131B5E;
}



input[type="radio"] {
    position: relative;
    opacity: 1;
}
