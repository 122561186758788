@import url('https://fonts.googleapis.com/css?family=Roboto');


a:active {
	border-bottom: 0.1em solid rgba(0, 0, 16, 0.8);
	color: rgba(0, 0, 16, 0.8);
	text-decoration: none;
}

.svg-custom {
	fill: rgb(200, 200, 200);
	height: 3.5rem;	
	width: 3.5rem;
	margin: 1.7rem;
}

.rating-form-2 svg {
	height: 2.8rem;
	width: 2.8rem;
	margin: 0.5rem;
}

#radios label {
	position: relative;
}

input[type="radio"] {
	position: absolute;
	opacity: 0;
}

input[type="radio"] + svg {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

input + svg {
	cursor: pointer;
}

input[class="super-happy"]:hover + svg,
input[class="super-happy"]:checked + svg,
input[class="super-happy"]:focus + svg {
	fill: rgb(0, 109, 217);
}

input[class="happy"]:hover + svg,
input[class="happy"]:checked + svg,
input[class="happy"]:focus + svg {
	fill: rgb(0, 204, 79);
}

input[class="neutral"]:hover + svg,
input[class="neutral"]:checked + svg,
input[class="neutral"]:focus + svg {
	fill: rgb(232, 214, 0);
}

input[class="sad"]:hover + svg,
input[class="sad"]:checked + svg,
input[class="sad"]:focus + svg {
	fill: rgb(229, 132, 0);
}

input[class="super-sad"]:hover + svg,
input[class="super-sad"]:checked + svg,
input[class="super-sad"]:focus + svg {
	fill: rgb(239, 42, 16);
}
