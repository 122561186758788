.columns-login {
    height: 100vh; /* Make the columns take the full height of the viewport */
    
}
.columns.columns-login{
    background-color: #ececec;
}

.column-login {
    padding: 0;
}
.field.mt-5{
    margin-top: 30em;
}

.image-column {
    flex-basis: 20%;
    display: flex;
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
}

.input.is-info{
    height: 50px;
    font-size: medium;
    border-radius: 20px;
    background-color: #ececec;
    border-color: #222b57;
    margin-bottom: 10px;
}
.label-login{
    margin-left: 1em;
    color: #000;
    font-size: small;
}
.button.is-link.w-100{
    height: 50px;
    border-radius: 20px;
    background-color: #e73457;
    border-color: #222b57;
}
.button.is-link.w-100:hover{
    background-color: #222b57;
}
.has-text-centered.has-text-link.mt-3.label{
    color: #4f2855;
}

.full-height {
    height: 102.3%; /* Make the image take the full height of the image column */
    width: 100%; /* Ensure the image maintains its aspect ratio */
    object-fit: cover; /* Scale and crop the image to fill the container */
    margin-bottom: -9px;
}

.w-100 {
    width: 100%;
}

.form-column {
    padding-top: 2rem;
    display: flex;
    align-items: center; /* Center the form vertically */
    justify-content: center; /* Center the form horizontally */
}

.form-width {
    width: 60%;
}

.blue-icon path {
    fill: #222b57;
}

.has-text-custom {
    color: #4E5486;
}

.line-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -40px;
}